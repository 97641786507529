import React from 'react'
import { Checkbox } from '@mui/material'
const TableHeadings = ({ TableHeadData, numberOfSelected, rowCount, onSelectAllClick, className, color }) => {
  return (
    <>
      <thead  >
        <tr className="text-start">
          {onSelectAllClick && (
            <th className=" border-0" style={{ backgroundColor: '#cec2de', color: color ? color : '#313131' }}>
              <Checkbox
                size="small"
                color="primary"
                indeterminate={numberOfSelected > 0 && numberOfSelected < rowCount}
                checked={rowCount > 0 && numberOfSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </th>
          )}
          {TableHeadData?.map((item, index) => (
            <th
              // /  className={className}
              className={`${className} ${item?.noPrint ? 'no-print' : ''}`}
              key={index} style={{ backgroundColor: className ? className : 'rgba(223, 223, 223, 1)', color: '#313131' }}>
              {item?.label}
            </th>
          ))}
        </tr>
      </thead>
    </>
  )
}

export default TableHeadings