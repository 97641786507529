import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify"
import { IoText } from "react-icons/io5";
import { BiSolidDownArrow } from "react-icons/bi";
import { MdOutlineTextFields } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { IoIosCheckbox } from "react-icons/io";
import { PiRadioButtonFill } from "react-icons/pi";
// import { medicalHistory, medicalHistoryDetails, addMedicalHistory, updateMedicalHistory, activeInactiveMedicalHistory, deleteMedicalHistory, medicalCategoryList } from '../../../repository/master-repo'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADD_TEMPLATE_LIBRARY, DELETE_TEMPLATE_LIBRARY, TEMPLATEL_CATEGORY_LIST, TEMPLATE_LIBRARY_DETAILS, TEMPLATE_LIBRARY_LIST, TEMPLATE_LIBRARY_STATUS_CHANGE, UPDATE_TEMPLATE_LIBRARY } from '../../../../repository/setup';
import useReduxStore from '../../../../hooks/useReduxStore';
import { adjustPageAfterDeletion } from '../../../../Utils';
const useTemplateLibrary = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: 1 })
    const [templateList, setTemplateList] = useState([])
    const [formCategoryList, setFormCategoryList] = useState([])
    const [filter, setFilter] = useState({ search: "" })
    const [viewFieldsArry, setViewFieldsArry] = useState([]);
    const [templateName, setTemplateName] = useState("");
    const [formCategory, setFormCategory] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [isCopyed, setIsCopyed] = useState(false)
    const { id } = useParams()
    const { selector: user } = useReduxStore('auth')
    const [tab, setTab] = useState('ADDFIELD')
    const [fields, setFields] = useState({})
    const fieldsArry = [
        { type: "Dropdown", label: "Dropdown", required: true, fieldSize: "col-12", options: [], icon: <BiSolidDownArrow /> },
        { type: "Multiple Choice", label: "Multiple Choice", required: true, fieldSize: "col-12", options: [], icon: <PiRadioButtonFill /> },
        { type: "CheckBox", label: "Checkbox", required: true, fieldSize: "col-12", options: [], icon: <IoIosCheckbox /> },
        { type: "Text", label: "Text", required: true, fieldSize: "col-12", options: [], icon: <IoText /> },
        { type: "Title", label: "Title", required: true, fieldSize: "col-12", options: [], icon: <MdOutlineTextFields /> },
        { type: "Date", label: "Date", required: true, fieldSize: "col-12", options: [], icon: <SlCalender /> }
    ]
    const fieldSizeData = [
        { title: "Small", value: "col-4" },
        { title: "Medium", value: "col-6" },
        { title: "Large", value: "col-12" },
    ]
    // -------------Form Section------------------

    const [templateModal, setTemplateModal] = useState({
        open: false,
        data: null
    })

    const [viewTemplateModal, setViewTemplateModal] = useState({
        open: false,
        data: null
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: null
    })

    useEffect(() => {
        getTemplateList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, location.pathname])
    useEffect(() => {
        getFormCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getTemplateList = async () => {
        try {
            let payload = {
                count: controller?.rowsPerPage,
                page: controller?.page,
                searchName: filter?.search,
                isDeleted: false,
                status: (location.pathname === '/template_library/drafts') ? "DRAFT" : "SAVED",
                clinicId: user?.clinic_id,
            }
            setSpinner(true)
            let response = await TEMPLATE_LIBRARY_LIST(payload)

            setTemplateList(response?.data)
            settotalCount(response?.count)
        } catch (error) {
            toast.error(error?.message)
            setTemplateList([])
            settotalCount(0)
        }
        setSpinner(false)
    }
    const getFormCategory = async () => {
        try {
            let payload = {
                count: 10000,
                isDeleted: false,
                searchName: "",
                clinicId: user?.clinic_id
            }
            setSpinner(true)
            let response = await TEMPLATEL_CATEGORY_LIST(payload)
            setFormCategoryList(response?.data)
        } catch (error) {
            toast.error(error?.message)
            setFormCategoryList([])
        }
        setSpinner(false)
    }

    const copyField = async (val, index) => {
        let newArry = viewFieldsArry
        newArry?.splice(index + 1, 0, val)
        setViewFieldsArry([...newArry]);
    }

    // apply Filter
    const ApplyFilter = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    // Reset Filter
    const resetFilter = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage, searchPage: 1 }))
        setFilter({ search: "" })
    }

    // Status toggle method
    const status = async (e, Id) => {
        setSpinner(true)
        try {
            let payload = {
                id: Id,
                isActive: e.target.checked,
            }
            let response = await TEMPLATE_LIBRARY_STATUS_CHANGE(payload)
            getTemplateList()
            toast.success(response?.message)
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }

    // ---------------------Form Functnality-----------------------------

    const getDetails = async (id) => {
        setSpinner(true)
        try {
            let response = await TEMPLATE_LIBRARY_DETAILS(id);
            setFilterStatus(response?.data?.status)
            setTemplateName(response?.data?.name)
            setFormCategory(response?.data?.formCategoryId)
            setViewFieldsArry(response?.data?.fields);
        } catch (error) {
            toast.error(error?.message)
        }
        setSpinner(false)
    }

    // Handle add submit data..!
    const handleAddSubmit = async (type) => {
        if (!formCategory) return toast.warn('Please select form category')
        if (!templateName) return toast.warn('Please enter form name ')
        if (!viewFieldsArry?.length) return toast.warn('Please add atleast 1 field')
        const optionsOnly = viewFieldsArry.filter(obj => obj.type === "Dropdown" || obj.type === "CheckBox" || obj.type === "Multiple Choice").map(obj => obj.options);
        const isEmptyOrNull = optionsOnly.some(options => !options || options.length === 0);
        if (isEmptyOrNull) return toast.warn('Please enter options of field')
        const isLabelText = viewFieldsArry.map((res) => res.label)
        if (isLabelText?.some(str => str === '')) return toast.warn('Please enter label name.');

        setSpinner(true)
        let payload = {
            name: templateName,
            formCategoryId: formCategory,
            status: type,
            // fields: [] = fields
            fields: !isCopyed ? viewFieldsArry : viewFieldsArry?.map(item => ({
                ...item,
                options: item?.options?.map(option => option?.name ? option?.name : option)
            })),
            clinicId: user?.clinic_id
        }
        try {
            let response = await ADD_TEMPLATE_LIBRARY(payload)
            getTemplateList()
            setTemplateModal({ open: false, data: null })
            navigate('/template_library')
            toast.success(response?.message)
        } catch (err) {
            toast.error(err?.message)
        }
        setSpinner(false)
    }
    // Handle Update submit data..!
    const handleupdateSubmit = async (type) => {
        if (!formCategory) return toast.warn('Please select form category')
        if (!templateName) return toast.warn('Please enter form name ')
        if (!viewFieldsArry?.length) return toast.warn('Please add atleast 1 field')

        try {
            const optionsOnly = viewFieldsArry.filter(obj => obj.type === "Dropdown" || obj.type === "CheckBox" || obj.type === "Multiple Choice").map(obj => obj.options);
            const isEmptyOrNull = optionsOnly.some(options => !options || options.length === 0);
            if (isEmptyOrNull) return toast.warn('Please enter options of field')
            const isLabelText = viewFieldsArry.map((res) => res?.label)
            if (isLabelText?.some(str => str === '')) return toast.warn('Please enter label name.');
            const newFields = viewFieldsArry?.map(item => ({
                ...item,
                options: item?.options?.map(option => option?.name ? option?.name : option)
            }));
            let payload = {
                id: templateModal?.data?.id,
                name: templateName,
                formCategoryId: formCategory,
                status: type ? type : filterStatus,
                fields: newFields
            }
            setSpinner(true)
            let response = await UPDATE_TEMPLATE_LIBRARY(payload)
            getTemplateList()
            toast.success(response?.message)
            setTemplateModal({ open: false, data: null })
            if (type === "DRAFT") {
                navigate('/template_library/drafts')
            } else {
                navigate('/template_library')
            }
        } catch (err) {
            toast.error(err?.message)
        }
        setSpinner(false)
    }
    const clearForm = () => {
        setViewFieldsArry([]);
        setTemplateName('');
        setFormCategory('');
        setIsCopyed(false)
    }
    // Handle delete category..!
    const handleDelete = async (event) => {
        setSpinner(true)
        let payload = {
            clinic_id: null,
            id: deleteModal?.data?.id,
            isDeleted: !deleteModal?.data?.isDeleted
        }
        try {
            let response = await DELETE_TEMPLATE_LIBRARY(payload)
            setDeleteModal({ data: null, open: false })
            toast.success(response?.message)
            adjustPageAfterDeletion({
                totalCount: totalCount,
                currentPage: controller.page,
                rowsPerPage: controller.rowsPerPage,
                onPageChange: (newPage) => setController((prev) => ({ ...prev, page: newPage })),
                onTotalCountChange: (newTotal) => settotalCount(newTotal),
            });
            await getTemplateList()

        } catch (err) {
            toast.error(err?.message)
        }
        setSpinner(false)
    }
    const handleViewFields = (result, index) => {
        let fieldIndex = viewFieldsArry.length - 1 + 1
        let convertedData = {
            label: result?.label,
            fieldSize: result?.fieldSize,
            required: result?.required,
            options: result?.options,
            type: result?.type,
            FieldIndex: fieldIndex
        };
        setViewFieldsArry([...viewFieldsArry, convertedData]);
        setFields(convertedData);
        setTab('FIELDOPTIONS')
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prevProps) => ({ ...prevProps, [name]: value }));
    };

    const editFieldOption = (val, index) => {
        let options = val.options.map((res) => res.name)
        setFields({
            label: val.label,
            fieldSize: val.fieldSize,
            required: val.required,
            options: id?.id ? options : val.options,
            type: val.type,
            FieldIndex: index
        })
        setTab('FIELDOPTIONS')
    }

    const deleteField = (index) => {
        let newArry = viewFieldsArry
        newArry?.splice(index, 1)
        setViewFieldsArry([...newArry]);
        if (!viewFieldsArry.length) setTab('ADDFIELD')
    }

    const handleChange = (value, name) => {
        console.log(value, "value")
        setFields((pre) => ({
            ...pre, [name]: value
        }))
    }
    const updatedymanicform = () => {
        if (!fields?.label && !fields?.fieldSize) return toast.warn('Please select field')
        viewFieldsArry?.forEach((element, index) => {
            if (fields?.FieldIndex === index) {
                viewFieldsArry[index] = fields;
            }
        });
        setFields({
            label: '',
            fieldSize: '',
            required: false,
            options: '',
            type: '',
            FieldIndex: ''
        })
        // viewFieldsArry?.forEach((element, index) => {
        //     viewFieldsArry[index] = fields;
        // });
        setTab('ADDFIELD')
    }

    return {
        data: {
            location,
            spinner,
            controller,
            totalCount,
            templateList,
            formCategoryList,
            filterStatus,
            viewFieldsArry,
            filter,
            deleteModal,
            templateName,
            formCategory,
            templateModal,
            viewTemplateModal,
            tab,
            fields,
            fieldsArry,
            fieldSizeData,
            isCopyed,
            // ---------------Form data
        },
        methods: {
            settotalCount,
            setController,
            setTemplateList,
            setFormCategoryList,
            setFormCategory,
            setTemplateName,
            status,
            setFilter,
            ApplyFilter,
            resetFilter,
            setViewFieldsArry,
            copyField,
            setDeleteModal,
            setTemplateModal,
            setViewTemplateModal,
            // -----------------------Form methods ---------------
            handleAddSubmit,
            getDetails,
            handleupdateSubmit,
            handleDelete,
            clearForm,
            setTab,
            setFields,
            handleInputChange,
            handleViewFields,
            handleChange,
            updatedymanicform,
            editFieldOption,
            deleteField,
            setIsCopyed,

        }
    }
}

export default useTemplateLibrary
