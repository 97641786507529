import React, { useState } from 'react';

const ReadMoreButton = ({ text, maxLength, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {isExpanded ? (
        <div>
          {text}
          <button className='border-0 bg-body text-primary fw-6 ms-1' onClick={()=> setIsExpanded(!isExpanded)}>Read Less</button>
        </div>
      ) : (
        <div>
          <span className={className}> {text?.slice(0, maxLength)} </span> 
          {text?.length > maxLength && (
           <>  <span>...</span> <button className='border-0 bg-body text-primary fw-6' onClick={()=> setIsExpanded(!isExpanded)}>Read More</button> </>
          )}
        </div>
      )}
    </div>
  );
};

export default ReadMoreButton;
