/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react'
import { toast } from "react-toastify"
import {
    PROCEDURE_LIST,
    ADD_PROCEDURE,
    UPDATE_PROCEDURE,
    PROCEDURE_STATUS_CHANGE,
    PROCEDURE_CATEGORY_LIST,
    PROCEDURE_DETAILS,
    DELETE_PROCEDURE
} from '../../../../repository/setup'
import { adjustPageAfterDeletion, handleTrimmedValue, requiredMessage, requiredSelect } from '../../../../Utils'
import { timeArry } from '../../../../constant'
import useReduxStore from '../../../../hooks/useReduxStore'
// import {PROCEDURE_LIST} from '../../../repository/setup'
const UseProcedureList = () => {
    const { selector: user } = useReduxStore('auth')
    const billableArry = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
    const [spinner, setSpinner] = useState(false)
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: 1 })
    const [filter, setFilter] = useState({ search: '' })
    const [procedureList, setProcedureList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [selectedSurfaceCounts, setSelectedSurfaceCounts] = useState()
    const [selectedSurface, setSelectedSurface] = useState()
    const [selectedToothsCounts, setSelectedToothsCounts] = useState()
    const [selectedTooths, setSelectedTooths] = useState()
    const [procedureInputs, setProcedureInputs] = useState({
        category: '',
        code: '',
        alt_code: '',
        description: '',
        abbrev: '',
        fees1: '',
        fees2: '',
        billable: '',
        time: [{ title: '10 Minutes', value: 10 }],
        isSaleTax: false
    })
    const [procedureError, setProcedureError] = useState({})
    const [procedureModal, setProcedureModal] = useState({
        open: false,
        data: {}
    })
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: {}
    })
    const [surfaceModal, setSurfaceModal] = useState({
        open: false,
        data: {}
    })
    const [toothsModal, setToothsModal] = useState({
        open: false,
        data: {}
    })

    const [surfaces, setSurfaces] = useState([
        { label: 'Lingual', value: 'L', checked: false },
        { label: 'Buccal', value: 'B', checked: false },
        { label: 'Mesial', value: 'M', checked: false },
        { label: 'Inscisal', value: 'I', checked: false },
        { label: 'Occlusal', value: 'O', checked: false },
        { label: 'Distal', value: 'D', checked: false },
    ]);

    const [teethsList, setTeethsList] = useState([
        { toothNumber: '1', toothType: 'molar', selected: false },
        { toothNumber: '2', toothType: 'molar', selected: false },
        { toothNumber: '3', toothType: 'molar', selected: false },
        { toothNumber: '4', toothType: 'premolar', selected: false },
        { toothNumber: '5', toothType: 'premolar', selected: false },
        { toothNumber: '6', toothType: 'canine', selected: false },
        { toothNumber: '7', toothType: 'incisor', selected: false },
        { toothNumber: '8', toothType: 'incisor', selected: false },
        { toothNumber: '9', toothType: 'incisor', selected: false },
        { toothNumber: '10', toothType: 'incisor', selected: false },
        { toothNumber: '11', toothType: 'canine', selected: false },
        { toothNumber: '12', toothType: 'premolar', selected: false },
        { toothNumber: '13', toothType: 'premolar', selected: false },
        { toothNumber: '14', toothType: 'molar', selected: false },
        { toothNumber: '15', toothType: 'molar', selected: false },
        { toothNumber: '16', toothType: 'molar', selected: false },

        { toothNumber: 'A', toothType: 'molar', selected: false },
        { toothNumber: 'B', toothType: 'molar', selected: false },
        { toothNumber: 'C', toothType: 'canine', selected: false },
        { toothNumber: 'D', toothType: 'incisor', selected: false },
        { toothNumber: 'E', toothType: 'incisor', selected: false },
        { toothNumber: 'F', toothType: 'incisor', selected: false },
        { toothNumber: 'G', toothType: 'incisor', selected: false },
        { toothNumber: 'H', toothType: 'canine', selected: false },
        { toothNumber: 'I', toothType: 'molar', selected: false },
        { toothNumber: 'J', toothType: 'molar', selected: false },
        { toothNumber: 'K', toothType: 'molar', selected: false },
        { toothNumber: 'L', toothType: 'molar', selected: false },
        { toothNumber: 'M', toothType: 'canine', selected: false },
        { toothNumber: 'N', toothType: 'incisor', selected: false },
        { toothNumber: 'O', toothType: 'incisor', selected: false },
        { toothNumber: 'P', toothType: 'incisor', selected: false },
        { toothNumber: 'Q', toothType: 'incisor', selected: false },
        { toothNumber: 'R', toothType: 'canine', selected: false },
        { toothNumber: 'S', toothType: 'molar', selected: false },
        { toothNumber: 'T', toothType: 'molar', selected: false },

        { toothNumber: '32', toothType: 'molar', selected: false },
        { toothNumber: '31', toothType: 'molar', selected: false },
        { toothNumber: '30', toothType: 'molar', selected: false },
        { toothNumber: '29', toothType: 'premolar', selected: false },
        { toothNumber: '28', toothType: 'premolar', selected: false },
        { toothNumber: '27', toothType: 'canine', selected: false },
        { toothNumber: '26', toothType: 'incisor', selected: false },
        { toothNumber: '25', toothType: 'incisor', selected: false },
        { toothNumber: '24', toothType: 'incisor', selected: false },
        { toothNumber: '23', toothType: 'incisor', selected: false },
        { toothNumber: '22', toothType: 'canine', selected: false },
        { toothNumber: '21', toothType: 'premolar', selected: false },
        { toothNumber: '20', toothType: 'premolar', selected: false },
        { toothNumber: '19', toothType: 'molar', selected: false },
        { toothNumber: '18', toothType: 'molar', selected: false },
        { toothNumber: '17', toothType: 'molar', selected: false },
    ]);


    const [editRowIndex, setEditRowIndex] = useState(null); // To track which row is being edited
    const [editableData, setEditableData] = useState({}); // Store the data being edited

    // Handle input change
    const handlePocidureCodeInputChange = (e, key) => {
        const value = e.target.value === "true" ? true : e.target.value === "false" ? false : e.target.value;
        setEditableData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Handle save
    const handleSetFormDetails = (resp, index) => {
        // Logic to save the data goes here. Example: call an API or update state
        setEditableData(() => {
            return {
                category: resp?.category,
                code: resp?.code,
                altCode: resp?.altCode,
                description: resp?.description,
                abbrev: resp?.abbrev,
                fees1: resp?.fees1,
                fees2: resp?.fees2,
                billable: resp?.billable,
                time: resp?.time,
                isSaleTax: resp?.isSaleTax,
                surfaceData: resp?.surfaceData,
                toothData: resp?.toothData,
            }
        })
        // Optionally: Update procedureList or call API to save changes
    };

    // Handle cancel (exit edit mode without saving)
    const handleCancel = () => {
        setEditRowIndex(null);
        setEditableData({});
    };


    //   ----------------------- methods ----------------------------

    const handleSelectedSurface = (event, label) => {
        setSurfaces(prevSurfaces =>
            prevSurfaces.map(surface =>
                surface.label === label ? { ...surface, checked: event.target.checked } : surface
            )
        );
    };


    const handleSelectedTooths = (event, toothNumber) => {
        // setPrimaryTeeths(prevTooths =>
        //     prevTooths?.map(tooths =>
        //         tooths.toothNumber === toothNumber ? { ...tooths, selected: event.target.checked } : tooths
        //   )
        // );
        setTeethsList(prevTooths =>
            prevTooths?.map(tooths =>
                tooths.toothNumber === toothNumber ? { ...tooths, selected: event.target.checked } : tooths
            )
        );
    };

    const clearForm = () => {
        setProcedureInputs({
            category: '',
            code: '',
            alt_code: '',
            description: '',
            abbrev: '',
            fees1: '',
            fees2: '',
            billable: '',
            time: '',
            isSaleTax: false
        })
        setProcedureError({})
        setTeethsList((prevTeeths) =>
            prevTeeths.map((tooth) => ({ ...tooth, selected: false }))
            // prevTeeths.map((tooth) => ({ ...tooth, selected: tooth.selected }))
        );
        setSurfaces((prevSurfaces) =>
            prevSurfaces.map((surface) => ({ ...surface, checked: false }))
        );
        setSelectedToothsCounts((pre) => { console.log(pre, 'preCount') })
        setSelectedSurfaceCounts('')
    }


    const geProcedureList = async () => {
        let payload = {
            count: controller.rowsPerPage,
            page: controller?.page,
            isDeleted: false,
            // isActive: true,
            clinicId: user?.clinic_id,
            searchName: filter?.search
        }
        setSpinner(true)
        try {
            let response = await PROCEDURE_LIST(payload)
            setProcedureList(response?.data)
            settotalCount(response?.count)
            setSpinner(false)
        } catch (error) {
            toast.error(error)
            setProcedureList([])
            settotalCount(0)
            setSpinner(false)
        }
    }

    useEffect(() => {
        geProcedureList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    useEffect(() => {
        getCategoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategoryList = async () => {
        let payload = {
            count: 1000,
            page: 1,
            searchName: "",
            isDeleted: false,
            isActive: true,
            clinicId: user?.clinic_id,
        }
        // setSpinner(true)
        try {
            let response = await PROCEDURE_CATEGORY_LIST(payload)
            setCategoryList(response?.data)
            // settotalCount(response.count)
            // setSpinner(false)
        } catch (error) {
            toast.error(error?.message)
            setCategoryList([])
            // setSpinner(false)
        }
    }

    // apply Filter
    const ApplyFilter = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    // Reset Filter
    const resetFilter = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage, searchPage: 1 }))
        setFilter({ search: "" })
    }

    // Status toggle method
    const status = async (data, toggle) => {
        setSpinner(true)
        try {

            let payload = {
                id: data?.id,
                isActive: toggle,
            }
            let response = await PROCEDURE_STATUS_CHANGE(payload)
            geProcedureList()
            clearForm()
            toast.success(response?.message)

        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }

    // ---------------------Form Functnality---------------------
    const getDetails = async (id) => {
        try {
            setSpinner(true)
            let response = await PROCEDURE_DETAILS(id)
            // matchAndSetProcedures(procedureCodeListData, response.data.procedures);
            let newTime = timeArry.filter(item => item?.value == response?.data?.time)
            let newBillableArry = billableArry.filter(item => item?.value == response?.data?.billable)
            let newCategory = categoryList?.filter(item => item?.id == response?.data?.category)
            // let newProviderTime = providerTimeArry.filter(item => item?.value == response.data.providerTime)
            setSelectedSurfaceCounts(response?.data?.surfaceData?.length)
            setSelectedToothsCounts(response?.data?.ToothData?.length)
            const updatedSurfaces = surfaces.map(surface => {
                const matchingData = response?.data?.surfaceData?.find(data => data.value === surface.value);
                if (matchingData) {
                    return { ...surface, checked: matchingData.checked };
                }
                return surface;
            });
            setSurfaces(updatedSurfaces);

            const updatedTeethsList = teethsList.map(tooth => {
                const matchingData = response?.data?.ToothData?.find(data => data.toothNumber === tooth.toothNumber);
                if (matchingData) {
                    return { ...tooth, selected: matchingData.value };
                }
                return tooth;
            });
            setTeethsList(updatedTeethsList);

            setProcedureInputs({
                code: response?.data?.code,
                alt_code: response?.data?.altCode,
                description: response?.data?.description,
                abbrev: response?.data?.abbrev,
                fees1: response?.data?.fees1,
                fees2: response?.data?.fees2,
                category: newCategory,
                time: newTime,
                billable: newBillableArry,
                isSaleTax: response?.data?.isSaleTax,
                // provider_time:newProviderTime,
            })
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }


    // ------------------------- handle input -------------------
    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        const finalValue = handleTrimmedValue(value);
        setProcedureInputs((prevProps) => ({ ...prevProps, [name]: finalValue }));

        setProcedureError((pre) => {
            if (typeof finalValue === "string" && finalValue.length === 0) {
                return { ...pre, [name]: "This field cannot be empty or contain only spaces." };
            }
            delete pre[name];
            return { ...pre };
        });
    }
    const handleBlur = (event) => {
        const { name, value } = event.target
        !value?.trim()?.length && setProcedureError((pre) => ({ ...pre, [name]: errorMessage[name] }))
    }

    // Error Message
    const errorMessage = {
        category: requiredSelect('category'),
        code: requiredMessage('code'),
        // alt_code: requiredMessage('alt code'),
        description: requiredMessage('description'),
        abbrev: requiredMessage('abbreviation '),
        // abbrev:requiredMessage('abbrevation'),
        // fees1: requiredMessage('fees 1'),
        // fees2: requiredMessage('fees 2'),
        time: requiredSelect('procedure time'),
        billable: requiredSelect('billable')
        // provider_time: requiredSelect('provider time'),
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!procedureInputs?.category) {
            errorObj['category'] = errorMessage['category']
        }
        if (!procedureInputs?.code) {
            errorObj['code'] = errorMessage['code']
        }
        // if (!procedureInputs?.alt_code) {
        //     errorObj['alt_code'] = errorMessage['alt_code']
        // }
        if (!procedureInputs?.description) {
            errorObj['description'] = errorMessage['description']
        }
        if (!procedureInputs?.abbrev) {
            errorObj['abbrev'] = errorMessage['abbrev']
        }
        // if (!procedureInputs?.abbrev) {
        //     errorObj['abbrev'] = errorMessage['abbrev']
        // }
        // if (!procedureInputs?.fees1) {
        //     errorObj['fees1'] = errorMessage['fees1']
        // }else if (procedureInputs?.fees1?.toString()?.match(regex)) {
        //     errorObj['fees1'] = 'Fees 1 should be only number'  
        // }
        // if (!procedureInputs?.fees2) {
        //     errorObj['fees2'] = errorMessage['fees2']
        // }else if (procedureInputs?.fees2?.toString()?.match(regex)) {
        //     errorObj['fees2'] = 'Fees 2 should be only number'  
        // }
        // if (!procedureInputs?.time) {
        //     errorObj['time'] = errorMessage['time']
        // }
        // if (!procedureInputs?.provider_time) {
        //     errorObj['provider_time'] = errorMessage['provider_time']
        // }
        if (!procedureInputs?.billable) {
            errorObj['billable'] = errorMessage['billable']
        }
        setProcedureError(errorObj)
        return !Object.keys(errorObj)?.length
    }

    const handleSubmitSurfaces = () => {
        if (!surfaces.filter(surface => surface.checked).length) return toast.warn("Please select surface")
        setSelectedSurfaceCounts(surfaces.filter(surface => surface.checked).length);
        setSelectedSurface(surfaces.filter(surface => surface.checked))
        setSurfaceModal({ data: null, open: false })
    }

    const handleSubmitTooths = () => {
        // setSelectedToothsCounts(permanentTeeths.filter(tooth => tooth.selected).length + primaryTeeths.filter(tooth => tooth.selected).length);
        // setSelectedTooths([...permanentTeeths.filter(tooth => tooth.selected), ...primaryTeeths.filter(tooth => tooth.selected)] )
        if (!teethsList.filter(tooth => tooth.selected).length) return toast.warn("Please select tooth number")
        setSelectedToothsCounts(teethsList.filter(tooth => tooth.selected).length);
        setSelectedTooths(teethsList.filter(tooth => tooth.selected))
        setToothsModal({ data: null, open: false })
    }

    // Handle add submit data..!
    const handleAddSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        // if(!selectedTooths?.length) return toast.warn("Please select tooth number")
        // if(!selectedSurface?.length) return toast.warn("Please select tooth surface")
        setSpinner(true)
        let payload = {
            category: procedureInputs?.category[0]?.id,
            clinicId: user?.clinic_id,
            code: procedureInputs?.code,
            alt_code: procedureInputs?.alt_code,
            description: procedureInputs?.description,
            abbrev: procedureInputs?.abbrev,
            fees1: procedureInputs?.fees1,
            fees2: procedureInputs?.fees2,
            time: procedureInputs?.time[0]?.value,
            billable: procedureInputs?.billable[0]?.value,
            isSaleTax: procedureInputs?.isSaleTax,
            surfaceData: selectedSurface ? selectedSurface : [],
            toothData: selectedTooths ? selectedTooths : []
        }
        try {
            let response = await ADD_PROCEDURE(payload)
            setProcedureModal({ data: "", open: false })
            clearForm()
            geProcedureList()
            setTimeout(() => {
                toast.success(response?.message)
            }, 500)
        } catch (err) {
            toast.error(err?.message)
        }
        setSpinner(false)
    }

    // Handle Update submit data..!
    const handleupdateSubmit = async (resp, index) => {
        // event.preventDefault()
        // if (!isValid()) return
        setSpinner(true)
        try {
            let payload = {
                // ...editableData,
                clinicId: user?.clinic_id,
                id: resp?.id,
                isActive: resp?.isActive,
                category: editableData?.category,
                // clinicId: null,
                code: editableData?.code,
                alt_code: editableData?.altCode,
                description: editableData?.description,
                abbrev: editableData?.abbrev,
                fees1: editableData?.fees1,
                fees2: editableData?.fees2,
                time: editableData?.time,
                billable: editableData?.billable,
                isSaleTax: editableData?.isSaleTax,
                surfaceData: selectedSurface ? selectedSurface : [],
                toothData: selectedTooths ? selectedTooths : [],
                // provider_time: procedureInputs?.provider_time[0].value,
            }

            let response = await UPDATE_PROCEDURE(payload)
            setProcedureModal({ data: "", open: false })
            clearForm()
            // await geProcedureList()
            toast.success(response.message)
            setProcedureList((prev) => {
                const updatedList = [...prev];
                updatedList[index] = {
                    ...updatedList[index],
                    code: editableData?.code,
                    ToothData: selectedTooths ? selectedTooths : [],
                    fees1: editableData?.fees1,
                    description: editableData?.description,
                    fees2: editableData?.fees2,
                    billable: editableData?.billable,
                    surfaceData: selectedSurface ? selectedSurface : [],
                    abbrev: editableData?.abbrev,
                    time: editableData?.time,
                    category: editableData?.category,
                    altCode: editableData?.altCode,
                    isSaleTax: editableData?.isSaleTax
                };
                return updatedList;
            });
            setEditRowIndex(null);
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }

    // Handle delete category..!
    const handleDelete = async (event) => {
        setSpinner(true)
        let payload = {
            id: deleteModal?.data?.id,
            isDeleted: true
        }
        try {
            let response = await DELETE_PROCEDURE(payload)
            setDeleteModal({ data: {}, open: false })
            adjustPageAfterDeletion({
                totalCount: totalCount,
                currentPage: controller.page,
                rowsPerPage: controller.rowsPerPage,
                onPageChange: (newPage) => setController((prev) => ({ ...prev, page: newPage })),
                onTotalCountChange: (newTotal) => settotalCount(newTotal),
            });
            await geProcedureList()
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }


    return {
        data: {
            deleteModal,
            filter,
            spinner,
            procedureModal,
            surfaceModal,
            toothsModal,
            surfaces,
            procedureList,
            controller,
            totalCount,
            procedureInputs,
            selectedSurfaceCounts,
            selectedToothsCounts,
            teethsList,
            procedureError,
            billableArry,
            categoryList,
            timeArry,

            // edit form table valuye
            editRowIndex,
            editableData,
        },
        methods: {
            setDeleteModal,
            setFilter,
            setProcedureModal,
            setSurfaceModal,
            setToothsModal,
            setController,
            settotalCount,
            clearForm,
            ApplyFilter,
            resetFilter,
            setProcedureInputs,
            getDetails,
            status,
            setProcedureError,
            handleInputChange,
            handleBlur,
            setCategoryList,
            handleSelectedSurface,
            handleSelectedTooths,
            handleSubmitSurfaces,
            handleSubmitTooths,
            handleAddSubmit,
            handleupdateSubmit,
            handleDelete,

            // edit table form-----
            handlePocidureCodeInputChange,
            // handleSave,
            handleCancel,
            setEditRowIndex,
            setEditableData,
            handleSetFormDetails
        }
    }
}

export default UseProcedureList
