import React, { useContext, useEffect } from 'react';
import './sidebar.css'; // Import CSS file for styling
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clanderIcon from "../../Assets/sidenav_icons/Group 48099172.png"
import patientProfile from "../../Assets/sidenav_icons/Group 48099173.png"
import chartIcon from "../../Assets/sidenav_icons/solar_chart-bold.png"
import carbon_plan from "../../Assets/sidenav_icons/carbon_plan.png"
import userSetting from "../../Assets/sidenav_icons/ic_baseline-manage-accounts.png"
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { GET_READ_WAITING_REQUEST_NOTIFICATION, userProfileURL } from '../../repository/common';
import useReduxStore from '../../hooks/useReduxStore';
import notesActive from '../../Assets/sidenav_icons/notesActive.png'
import notesInActive from '../../Assets/sidenav_icons/notesInactive.png'
import treatmentActive from '../../Assets/sidenav_icons/treatmentActive.png'
import toothActive from '../../Assets/sidenav_icons/toothActive.png'
import userActive from '../../Assets/sidenav_icons/userActive.png'
import calenderActive from '../../Assets/sidenav_icons/calenderActive.png'
import userProfileActive from '../../Assets/sidenav_icons/userProfileActive.png'
import { NotificationsState } from '../../App';



const Sidebar = () => {
  const navigate = useNavigate()
  const { selector: user } = useReduxStore("auth")
  const { selector: PatientData } = useReduxStore("PatientData")
  const isPatient = PatientData?.id ? true : false
  const { setBudgeNotification } = useContext(NotificationsState)
  const location = useLocation()
  const isAppointmentScheduler = "/appointment_scheduler" === location?.pathname;
  const getBudgeNotification = async () => {
    try {
      let payload = {
        userId: user?.user_id
      };
      let response = await GET_READ_WAITING_REQUEST_NOTIFICATION(payload);
      setBudgeNotification({
        waitingList: !response?.data?.waitingList ? 1 : 0,
        requrestAppointment: !response?.data?.requestAppointment ? 1 : 0
      })
    } catch (error) {
    }
  };
  useEffect(() => {
    if (isAppointmentScheduler) {
      getBudgeNotification()
    }
  }, [isAppointmentScheduler])

  return (
    // <div className="sidebar">
    <div className="sidebar-content">
      <div className="sidebar-menu">
        <div className='navlink'>
          <Tooltip title="Appointment Scheduler">
            {/* <NavLink to={"/appointment_scheduler"}><img alt='' src={clanderIcon} /></NavLink> */}
            {isPatient ? (
              <NavLink to="/appointment_scheduler" >
                {({ isActive }) => (<img alt="" src={isActive ? calenderActive : clanderIcon} />
                )}
              </NavLink>
            ) : (<img alt="" src={clanderIcon} />
            )}
          </Tooltip></div>
        <div className='navlink'>
          <Tooltip title="Patient Management">
            {/* {isPatient ? <NavLink to={"/patient-management"}><img alt='' src={patientProfile} /></NavLink> : <img alt='' src={patientProfile} />} */}
            {isPatient ? (
              <NavLink to="/patient-management">
                {({ isActive }) => (<img alt="" src={isActive ? userProfileActive : patientProfile} />
                )}
              </NavLink>
            ) : (<img alt="" src={patientProfile} />
            )}
          </Tooltip>
        </div>
        <div className='navlink'>
          <Tooltip title="Charting">
            {/* {
              isPatient ? <NavLink to={"/charting"}><img alt='' src={chartIcon} /></NavLink> : <img alt='' src={chartIcon} />
            } */}

            {isPatient ? (
              <NavLink to="/charting">
                {({ isActive }) => (<img alt="" src={isActive ? toothActive : chartIcon} />
                )}
              </NavLink>
            ) : (<img alt="" src={chartIcon} />
            )}

          </Tooltip></div>
        <div className='navlink'>
          <Tooltip title="Plan">
            {/* {
              isPatient ? <NavLink to={"/plans"}><img alt='' src={carbon_plan} /></NavLink> : <img alt='' src={carbon_plan} />
            } */}

            {isPatient ? (
              <NavLink to="/plans">
                {({ isActive }) => (<img alt="" src={isActive ? treatmentActive : carbon_plan} />
                )}
              </NavLink>
            ) : (<img alt="" src={carbon_plan} />
            )}

          </Tooltip></div>

        <div className='navlink'>
          <Tooltip title="Progress Notes">
            {/* {
              isPatient ? <NavLink to={"/progress-notes"}><img alt='' src={notesInActive} /></NavLink> : <img alt='' src={notesInActive} />
            } */}
            {isPatient ? (
              <NavLink to="/progress-notes">
                {({ isActive }) => (<img alt="" src={isActive ? notesActive : notesInActive} />
                )}
              </NavLink>
            ) : (<img alt="" src={notesInActive} />
            )}

          </Tooltip>
        </div>


        <div className='navlink'>
          <Tooltip title="Account">
            {/* {
              isPatient ? <NavLink to={"/accounts"}><img alt='' src={userSetting} /></NavLink> : <img alt='' src={userSetting} />
            } */}

            {isPatient ? (
              <NavLink to="/accounts">
                {({ isActive }) => (<img alt="" src={isActive ? userActive : userSetting} />
                )}
              </NavLink>
            ) : (<img alt="" src={userSetting} />
            )}

          </Tooltip></div>

      </div>
      <div className='d-flex justify-content-center cursor'>
        <Tooltip title="Profile">
          <Avatar
            // alt="Remy Sharp"
            // src="/static/images/avatar/1.jpg"
            src={userProfileURL + user?.image}
            sx={{ width: 40, height: 40 }}
            onClick={() => navigate("/clinic")}
          />
        </Tooltip>
      </div>
    </div>
    // </div>
  );
}

export default Sidebar;
