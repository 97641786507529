import React, { useEffect, useState } from 'react'
import FormField from '../../../Components/common-components/form-input';
import { Switch } from '@mui/material';
import { MdOutlineTimerOff } from "react-icons/md";
import { toast } from 'react-toastify';
import { Spinner } from '../../../Components/common-components/spinner';
import useReduxStore from '../../../hooks/useReduxStore';
import { POLICY_LIST, UPDATE_POLICY } from '../../../repository/setup';


const Policies = () => {
    const [spinner, setSpinner] = useState(false);
    const [notesError, setNotesError] = useState({})
    const [defaultPolicy, setDefaultPolicy] = useState({
        rescheduleTime: 0,
        cancelationTime: 0,
        noShowCharge: 0,
        shortCancelationTime: 0,
        isAllowReschedule: false,
        isAllowCancellation: false,
        isAllowShortCancellation: false,
        isAllowNoShow: false,
    })
    const { selector: user } = useReduxStore('auth')

    // get note type list
    const getPolicyList = async () => {
        let payload = {
            // clinicId: user?.clinic_id,
            userId: user?.user_id,
        }
        try {
            setSpinner(true)
            let response = await POLICY_LIST(payload)
            setDefaultPolicy(response?.data)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    // const errorMessage = {
    //     rescheduleTime: requiredMessage('reschedule time'),
    //     cancelationTime: requiredMessage('cancelation time'),
    //     noShowCharge: requiredMessage('show charge'),
    //     shortCancelationTime: requiredMessage('short Cancelation time'),

    // }

    // Error hendling
    // const isValid = () => {
    //     const errorObj = {}
    //     if (defaultPolicy.isAllowReschedule && !defaultPolicy.rescheduleTime) {
    //         errorObj['rescheduleTime'] = errorMessage['rescheduleTime']
    //     } else {
    //         delete errorObj?.rescheduleTime
    //     }
    //     if (defaultPolicy.isAllowCancellation && !defaultPolicy.cancelationTime) {
    //         errorObj['cancelationTime'] = errorMessage['cancelationTime']
    //     } else {
    //         delete errorObj?.cancelationTime
    //     }
    //     if (defaultPolicy.isAllowNoShow && !defaultPolicy.noShowCharge) {
    //         errorObj['noShowCharge'] = errorMessage['noShowCharge']
    //     } else {
    //         delete errorObj?.noShowCharge
    //     }
    //     if (defaultPolicy.isAllowShortCancellation && !defaultPolicy.shortCancelationTime) {
    //         errorObj['shortCancelationTime'] = errorMessage['shortCancelationTime']
    //     } else {
    //         delete errorObj?.shortCancelationTime
    //     }
    //     setNotesError(errorObj)
    //     return !Object.keys(errorObj)?.length
    // }
    // handle change input
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setDefaultPolicy((pre) => ({ ...pre, [name]: value }))
        setNotesError((pre) => {
            delete pre[name];
            return { ...pre };
        });
    }
    // handle blue
    // const handleBlur = (event) => {
    //     const { name, value } = event.target
    //     !value?.trim()?.length && setNotesError((pre) => ({ ...pre, [name]: errorMessage[name] }))
    // }
    const handleToggle = (e, type) => {
        const { name, checked } = e.target
        setDefaultPolicy((pre) => ({ ...pre, [name]: checked }))
    }
    //  update
    const handleUpdateSubmit = async () => {
        // if (!isValid()) return
        setSpinner(true)
        let { id, ...restVlaue } = defaultPolicy
        let payload = {
            ...restVlaue,
            rescheduleTime: !defaultPolicy?.rescheduleTime ? 0 : defaultPolicy?.rescheduleTime,
            cancelationTime: !defaultPolicy?.cancelationTime ? 0 : defaultPolicy?.cancelationTime,
            noShowCharge: !defaultPolicy?.noShowCharge ? 0 : defaultPolicy?.noShowCharge,
            shortCancelationTime: !defaultPolicy?.shortCancelationTime ? 0 : defaultPolicy?.shortCancelationTime,
        }
        try {
            let response = await UPDATE_POLICY(payload)
            getPolicyList()
            setTimeout(() => {
                toast.success(response?.message)
            }, 500)
        } catch (err) {
            toast.error(err?.message)
        }
        setSpinner(false)
    }
    useEffect(() => {
        getPolicyList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {spinner && <Spinner />}
            <div className='container-fluid p-1'>
                <div className='text-center section_header m-0'>Policies</div>
                {/* rescheduling code start  */}
                <div className='border mt-3'>

                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom'>
                            <div className='d-flex align-items-center pb-2'>
                                <div className='bgGrey6 rounded-circle d-flex align-items-center justify-content-center' style={{ height: '40px', width: '40px' }}><MdOutlineTimerOff style={{ height: '22px', width: '22px', color: '#fff' }} /> </div>
                                <div className='fw-bold font-20 twoWayHeading ms-3 black1'>Rescheduling Policy</div>
                            </div>
                            <div>
                                <Switch title='Status Active/In-active' name='isAllowReschedule' onChange={(e) => handleToggle(e, "rescheduleTime")} checked={defaultPolicy?.isAllowReschedule} />
                            </div>
                        </div>
                        <div className='mt-3 font-13 grey5'> User can reschedule appointments with in the system </div>
                        <div className='d-flex align-items-center mt-3 font-13 grey5' style={{ opacity: `${defaultPolicy?.isAllowReschedule ? "1" : "0.5"}` }}> Allow rescheduling before     <div className="  px-2">
                            <div style={{ width: "70px" }}>
                                <FormField mTop='0'
                                    readOnly={!defaultPolicy?.isAllowReschedule}
                                    required={true}
                                    name={'rescheduleTime'}
                                    value={defaultPolicy?.rescheduleTime}
                                    // onBlur={(e) => handleBlur(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    data={{ label: '', placeholder: '', type: "number" }}
                                />
                            </div>
                        </div> hours of fixed appointment day.  </div>
                        {
                            notesError?.rescheduleTime && <div className='font-13 mt-1' style={{ color: 'red' }}>{notesError?.rescheduleTime}</div>
                        }
                    </div>

                </div>

                {/* cancelation code start  */}
                <div className='border mt-3'>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom'>
                            <div className='d-flex align-items-center pb-2'>
                                <div className='bgGrey6 rounded-circle d-flex align-items-center justify-content-center' style={{ height: '40px', width: '40px' }}><MdOutlineTimerOff style={{ height: '22px', width: '22px', color: '#fff' }} /> </div>
                                <div className='fw-bold font-20 twoWayHeading ms-3 black1'>Cancelation Policy</div>
                            </div>
                            <div>
                                <Switch title='Status Active/In-active' name='isAllowCancellation' onChange={(e) => handleToggle(e, "cancelationTime")} checked={defaultPolicy?.isAllowCancellation} />
                            </div>
                        </div>

                        <div className='mt-3 font-13 grey5'> Allow Patient to cancel appointment?</div>
                        <div className='d-flex align-items-center mt-3 font-13 grey5' style={{ opacity: `${defaultPolicy?.isAllowCancellation ? "1" : "0.5"}` }}> Allow cancelation before  <div className="  px-2">
                            <div style={{ width: "70px" }}>
                                <FormField mTop='0'
                                    required={true}
                                    name={'cancelationTime'}
                                    readOnly={!defaultPolicy?.isAllowCancellation}
                                    value={defaultPolicy?.cancelationTime}
                                    // onBlur={(e) => handleBlur(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    data={{ label: '', placeholder: '', type: "number" }}
                                />
                            </div>
                        </div> hours of fixed appointment day.  </div>
                        {
                            notesError?.cancelationTime && <div className='font-13 mt-1' style={{ color: 'red' }}>{notesError?.cancelationTime}</div>
                        }
                    </div>

                </div>

                {/* short cancelation code start  */}
                <div className='border mt-3'>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom'>
                            <div className='d-flex align-items-center pb-2'>
                                <div className='bgGrey6 rounded-circle d-flex align-items-center justify-content-center' style={{ height: '40px', width: '40px' }}><MdOutlineTimerOff style={{ height: '22px', width: '22px', color: '#fff' }} /> </div>
                                <div className='fw-bold font-20 twoWayHeading ms-3 black1'>Short Cancelation Policy</div>
                            </div>
                            <div>
                                <Switch title='Status Active/In-active' name='isAllowShortCancellation' onChange={(e) => handleToggle(e, "shortCancelationTime")} checked={defaultPolicy?.isAllowShortCancellation} />
                            </div>
                        </div>

                        <div className='mt-3 font-13 grey5'> Clinic will define a time window with in which users can cancel without penalty</div>
                        <div className='d-flex align-items-center mt-3 font-13 grey5' style={{ opacity: `${defaultPolicy?.isAllowShortCancellation ? "1" : "0.5"}` }}> Cancel without penalty <div className="  px-2">

                            <div style={{ width: "70px" }}>
                                <FormField mTop='0'
                                    required={true}
                                    name={'shortCancelationTime'}
                                    value={defaultPolicy?.shortCancelationTime}
                                    readOnly={!defaultPolicy?.isAllowShortCancellation}
                                    // onBlur={(e) => handleBlur(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    data={{ label: '', placeholder: '', type: "number" }}
                                />

                            </div>
                        </div> hours before the scheduled time  </div>
                        {
                            notesError?.shortCancelationTime && <div className='font-13 mt-1' style={{ color: 'red' }}>{notesError?.shortCancelationTime}</div>
                        }
                    </div>

                </div>

                {/* No show code start  */}
                <div className='border mt-3'>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom'>
                            <div className='d-flex align-items-center pb-2'>
                                <div className='bgGrey6 rounded-circle d-flex align-items-center justify-content-center' style={{ height: '40px', width: '40px' }}><MdOutlineTimerOff style={{ height: '22px', width: '22px', color: '#fff' }} /> </div>
                                <div className='fw-bold font-20 twoWayHeading ms-3 black1'>No Show Policy</div>
                            </div>
                            <div>
                                <Switch title='Status Active/In-active' name='isAllowNoShow' onChange={(e) => handleToggle(e, "noShowCharge")} checked={defaultPolicy?.isAllowNoShow} />
                            </div>
                        </div>

                        <div className='mt-3 font-13 grey5'>Change from clinic to patient in case of No Show</div>
                        <div className='d-flex align-items-center mt-3 font-13 grey5' style={{ opacity: `${defaultPolicy?.isAllowNoShow ? "1" : "0.5"}` }}> Change for No Show($) <div className="  px-2">


                            <div style={{ width: "200px" }}>

                                <FormField mTop='0'
                                    required={true}
                                    name={'noShowCharge'}
                                    value={defaultPolicy?.noShowCharge}
                                    readOnly={!defaultPolicy?.isAllowNoShow}
                                    // onBlur={(e) => handleBlur(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    // error={notesError?.noShowCharge}
                                    data={{ label: '', placeholder: '', type: "number" }}
                                />

                            </div>
                        </div>
                        </div>
                        {
                            notesError?.noShowCharge && <div className='font-13 mt-1' style={{ color: 'red' }}>{notesError?.noShowCharge}</div>
                        }
                    </div>

                </div>
                <div className='w-100 d-flex justify-content-end'>
                    <button type="button" className="theme-btn bgDarkBlue  ms-2 px-5 mt-3" onClick={handleUpdateSubmit} >Save</button>
                </div>
            </div>
        </>
    )
}

export default Policies
