import React, { useContext } from 'react'
import './header.css';
import { NavLink, useNavigate } from 'react-router-dom';
import AddIcon from "../../Assets/Clinic_head_icons/addIcon.png"
import { IoEye } from "react-icons/io5";
import { ImFileText } from "react-icons/im";
import { IoMdEyeOff } from "react-icons/io";
import Badge from '@mui/material/Badge';
import { NotificationsState } from '../../App';

const AppointmentHeader = ({ methods, data }) => {
    const navigate = useNavigate()
    // const [waitingListNew, setWaitingListNew] = React.useState("false");
    // let waitingListNew = false
    const { BudgeNotification } = useContext(NotificationsState)
    const {
        hippModeOnOff
    } = data
    const {
        setCreateAppointmentModal,
        updatePrivacyMode

    } = methods


    // setTimeout(()=>{

    //     console.log('updated')
    //     setWaitingListNew(0)

    // }, 5000)


    return (
        <>
            <header id="header" className={`nestedHeader  d-flex align-items-center  `} style={{ background: "#0F6CBD" }}>
                <nav>
                    <ul className="nav-links">
                        <li onClick={() => setCreateAppointmentModal({ data: null, open: true, type: "CREATE" })}><img src={AddIcon} alt='' />Create Appointment</li>
                        <li className='d-flex align-items-center ' onClick={() => { updatePrivacyMode(!hippModeOnOff) }}> {hippModeOnOff ? <IoMdEyeOff className='font-18 me-1' /> : <IoEye className='font-18 me-1' />}  Privacy Mode</li>
                        <NavLink to={"/waiting_list"}>
                            <li>
                                <img src={AddIcon} alt='' />
                                <Badge color="warning" variant="dot" invisible={BudgeNotification?.waitingList}>
                                    Waiting List
                                </Badge>
                            </li>
                        </NavLink>
                        <li className='d-flex align-items-center '><NavLink to="/appointment-requests" ><ImFileText className='font-18 me-1' />
                            <Badge color="warning" variant="dot" invisible={BudgeNotification?.requrestAppointment}>
                                Appointment Requests
                            </Badge>
                        </NavLink></li>
                    </ul>
                </nav>
            </header >
            {/* add appointment Modal */}


        </>
    )
}

export default AppointmentHeader