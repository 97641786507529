import React, { Suspense, useEffect, useContext, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import AppointmentHeader from './AppointmentHeader';
import ChartingHeader from './ChartingHeader';
import useReduxStore from '../../hooks/useReduxStore';
import moment from 'moment-timezone';
import useHeader from './Hooks/useHeader';
import { Spinner } from "../common-components/spinner"
import userIcon from "../../Assets/images/user2.png"
import idteenyicons from "../../Assets/images/idteenyicons.png"
import cake from "../../Assets/images/cake.png"
import teliphone from "../../Assets/images/teliphone.png"
import PatInsurance from "../../Assets/images/insurance.png"


import gender from "../../Assets/images/gender.png"
import { environment } from '../../env/environment';
import { handalDOB, privacyStars } from '../../Utils';
import { NotificationsState } from '../../App'
import { READ_WAITING_REQUEST_LIST } from '../../repository/common';

const NavigationLayout = () => {
    // const [isHeader, setIsHeader] = useState(true)
    const { setSocket, setBudgeNotification } = useContext(NotificationsState)
    const { selector: user } = useReduxStore("auth")
    const location = useLocation()
    const pathnameRef = useRef(location.pathname);
    pathnameRef.current = location.pathname;
    const isAppointmentHeader = (pathname) => ["/appointment_scheduler", "charting"]?.includes(pathname);
    const { selector: PatientData } = useReduxStore("PatientData")
    const { data, methods, } = useHeader()
    const { spinner, createAppointmentModal, patientListData, hippModeOnOff } = data
    const { setCreateAppointmentModal } = methods


    const getReadBudgeNotification = async (type) => {
        const payload = {
            userId: user?.user_id,
            type,
        };

        try {
            await READ_WAITING_REQUEST_LIST(payload);
            setBudgeNotification((prev) => ({
                ...prev,
                [type === "WAITINGLIST" ? "waitingList" : "requrestAppointment"]: 1,
            }));
        } catch (error) {
            console.error("Error reading badge notification:", error);
        }
    };
    useEffect(() => {
        let ws;
        let reconnectInterval;
        const connectWebSocket = () => {
            let ws = new WebSocket(`${environment?.socketUrl}/dental/websocket`);
            ws.onopen = () => {
                setSocket(ws);
                const data = {
                    eventType: "register",
                    userId: user?.user_id,
                };
                ws.send(JSON.stringify(data));
            };
            ws.onmessage = (data) => {
                const notificationDetails = JSON.parse(data?.data);
                const { title, status } = notificationDetails?.data || {};

                setBudgeNotification((prev) => {
                    const updatedData = { ...prev };
                    const currentPath = pathnameRef.current;
                    if (currentPath === "/appointment-requests") {
                        getReadBudgeNotification("REQUESTAPPOINTMENT");
                    } else if (title === "BADGEREQUESTAPPOINTMENT") {
                        updatedData.requrestAppointment = status ? 0 : 1;
                    }

                    if (currentPath === "/waiting_list") {
                        getReadBudgeNotification("WAITINGLIST");
                    } else if (title === "BADGEWAITINGLIST") {
                        updatedData.waitingList = status ? 0 : 1;
                    }

                    return updatedData;
                });
            };
            ws.onerror = (error) => {
                attemptReconnect();
            };
            ws.onclose = () => {
                attemptReconnect();
            };
        };

        const attemptReconnect = () => {
            clearInterval(reconnectInterval);
            reconnectInterval = setInterval(() => {
                if (ws?.readyState === WebSocket?.CLOSED) {
                    connectWebSocket();
                } else {
                    clearInterval(reconnectInterval);
                }
            }, 5000);
        };
        connectWebSocket();
        return () => {
            if (ws) {
                ws?.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="navigation-layout">
            {spinner && <Spinner />}

            {/* <PatientHeader data={data} methods={methods} /> */}
            <Header data={data} methods={methods} />
            {/* <div className={`content-wrapper`}> */}
            <div className={`d-flex`}>
                <Sidebar />
                <div className="main-content">
                    {/* <div className='MAIN_CONTAINER'> */}
                    <div className='h-100'>
                        {location?.pathname === "/appointment_scheduler" && < AppointmentHeader data={data} methods={methods} />}
                        {location?.pathname === "/charting" && <ChartingHeader />}
                        <header id="header" className='nestedHeader  d-flex align-items-center ' style={{ gap: "40px" }}>
                            <div className='blue-color pe-3 py-2 fw-6' style={{ borderRight: "1px solid gray" }}>Patient Information: </div>
                            <div className='font-12'>
                                <img alt='' title='Name' src={userIcon} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff ? privacyStars(PatientData?.firstName) : (`${PatientData?.firstName ? PatientData?.firstName : "N/A"} ${PatientData?.lastName ? PatientData?.lastName : ''}`)}
                                </span>
                            </div>
                            <div className='font-12'>
                                <img alt='' title='D.O.B' src={cake} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff ? privacyStars(PatientData?.dob) : (`${PatientData?.dob ? moment(PatientData?.dob).format('DD-MM-YYYY') : 'N/A'}`)}
                                </span>
                            </div>
                            <div className='font-12'>
                                <img alt='' title='Patient Id' src={idteenyicons} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff ? privacyStars(PatientData?.patientId) : (` ${PatientData?.patientId ? PatientData?.patientId : "N/A"}`)}
                                </span>
                            </div>
                            <div className='font-12'>
                                <img alt='' title='Phone' src={teliphone} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff ? privacyStars(PatientData?.phone) : (`${PatientData?.phone ? PatientData?.phone : "N/A"}`)}
                                </span>
                            </div>

                            <div className='font-12'>
                                <img alt='' title='Age/Gender' src={gender} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff
                                        ? privacyStars(PatientData?.dob)
                                        : (
                                            PatientData?.dob
                                                ? `${handalDOB(PatientData?.dob)}, ${PatientData?.gender === 'M' ? 'Male' : PatientData?.gender === 'F' ? 'Female' : PatientData?.gender === 'O' ? 'Other' : "N/A"}`
                                                : `${PatientData?.gender === 'M' ? 'Male' : PatientData?.gender === 'F' ? 'Female' : PatientData?.gender === 'O' ? "Other" : "N/A"}`
                                        )
                                    }
                                </span>
                            </div>

                            <div className='font-12'>
                                <img alt='' title='Insurance' src={PatInsurance} />
                                <span style={{ filter: hippModeOnOff ? 'blur(3px)' : "" }}>
                                    {hippModeOnOff ? privacyStars(PatientData?.primaryInsurance) : (` ${PatientData?.primaryInsurance ? PatientData?.primaryInsurance : "N/A"}`)}
                                </span>
                            </div>
                        </header>
                        {/* <div className={`nested_content ${(location?.pathname === "/appointment_scheduler" || location.pathname === "charting") && "nested_content_AppointmentHeader"}`}> */}
                        <div className={`${location?.pathname == '/charting' ? 'nested_content_charting' : 'nested_content'} ${isAppointmentHeader(location?.pathname) && "nested_content_AppointmentHeader"}`}>
                            {/* <button onClick={() => setCount((prev) => prev + 1)}>Count = {count}</button> */}
                            <Suspense fallback={"Loading..."}>
                                <Outlet context={{ setCreateAppointmentModal, createAppointmentModal, patientListData }} />
                            </Suspense>
                            {/* {count > 5 && <Suspense fallback={"...Loading ooooo"}><ClinicDetails /></Suspense>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NavigationLayout;