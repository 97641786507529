import React from 'react'
import PrintIcon from '@mui/icons-material/Print';
import UsePerioChart from './Hooks/usePerioChart';
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from "../../Components/PopUpModal";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import { Spinner } from '../../Components/common-components/spinner';
// import { ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import { Switch } from '@mui/material';
import { Typography, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import './perioChart.css';
import MultipleSelect from "../../Components/MultipleSelect";
import { dentitionAgeArray } from '../../constant';
import { Permission } from '../../Utils';



const PerioChart = () => {
    const btnNo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, -1, -2, -3, -4, -5, -6, -7, -8, -9]
    const { data, methods } = UsePerioChart()
    const { perioChartForm,
        selectInput,
        chartSettingModal,
        selectedAllPermissions,
        assignedPermissions,
        selectedAssignedPermissions,
        allChart,
        assignedChart,
        chartPosition,
        focusedInput,
        spinner,
        timeListData,
        disabled,
        selectedTime,
        inputRefs,
        chartAddModal,
        date,
        perioUpdate,
        autoSave,
        values,
        routingData,
        dentitionAge
    } = data

    const { renderRows,
        handleButtonClick,
        setChartSettingModal,
        handleDragOver,
        handleDragStart,
        handleItemClick,
        handleDrop,
        clearFunction,
        handleSubmit,
        setChartPosition,
        handleNumberType,
        handleFocusChange,
        chartList,
        setSelectedTime,
        handlePrint,
        setChartAddModal,
        newChartAdd,
        previousCopyChart,
        setDate,
        handleDecrement,
        handleIncrement,
        autoSaveToggle,
        handleManualUpdate,
        handleManualAdd,
        setDentitionAge,
        handleSelectChange
    } = methods
    const navigate = useNavigate()


    const capitalizeWords = str => str?.replace(/_/g, ' ').replace(/\b\w/g, char => char?.toUpperCase());
    const originalFields = assignedChart?.map(capitalizeWords);

    const handleClick = () => {
        const queryParams = new URLSearchParams(routingData);
        navigate(`/perio-graph?${queryParams.toString()}`);
    };

    const Add_Permission = Permission({ permission: "Charting-Perio Charting-Able to add perio chart" })
    const View_Permission = Permission({ permission: "Charting-Perio Charting-Able to view perio chart" })
    const Edit_Permission = Permission({ permission: "Charting-Perio Charting-Able to edit Perio Chart" })
    return (
        <div className='container-fluid'>
            {spinner && <Spinner />}
            <div className='d-flex justify-content-between mt-3 align-items-center'>
                <div className='font-25 fw-6'> Perio Chart</div>
                <div className='d-flex '>
                    <input type="date" className='form-control mt-3 font-11' value={date} onChange={(e) => setDate(e.target.value)} name="" id="" style={{ width: '150px', height: '35px' }} />
                    <select name="" id="" className='form-select mx-3 mt-3 pt-1 font-11' style={{ width: '150px', height: '35px' }}
                        onChange={(e) => { chartList(e.target.value); setSelectedTime(e.target.value) }} value={selectedTime}>
                        <option value="" hidden>Select Date</option>
                        {
                            timeListData?.map((item, index) =>
                                <option key={index} value={item.createdDate}>{moment(item.createdDate).format('MMM Do YYYY')} </option>
                            )
                        }
                    </select>
                    {/* <select name="" id="" className='form-select me-3 mt-3 pt-1' style={{ width: '150px', height: '35px' }}
                        onChange={(e) => { chartList(e.target.value); setSelectedTime(e.target.value) }} value={selectedTime}>
                        <option value="" hidden>Select Date</option>
                        {
                            timeListData?.map((item, index) =>
                                <option key={index} value={item.createdDate}>{moment(item.createdDate).format('MMM Do YYYY')} </option>
                            )
                        }
                    </select> */}
                    <div className='mt-2 me-3' style={{ width: '250px' }}>
                        <MultipleSelect
                            multiple={false}
                            searchBy={"name"}
                            keyName={"label"}
                            name={"billable"}
                            // onChange={(e) => { setDentitionAge(e) }}
                            onChange={(e) => handleSelectChange(e, true)}
                            value={"value"}
                            selectedvalues={dentitionAge}
                            options={dentitionAgeArray}
                        />
                    </div>
                    {
                        perioUpdate?.update ?
                            <button className="theme-btn bgLightGreen mt-3 me-3" onClick={() => handleManualUpdate()} disabled={disabled}>Save</button>
                            :
                            <button className="theme-btn bgLightGreen mt-3 me-3" onClick={() => handleManualAdd()} disabled={disabled}>Save</button>
                    }
                    <button className="theme-btn bgLightGreen mt-3 me-3" disabled={disabled} onClick={() => { setChartAddModal((pre) => ({ ...pre, open: true })) }}><AddIcon style={{ height: "20px" }} /> Add New</button>
                    <button className="theme-btn bgLightGreen mt-3 me-3" onClick={() => { handleClick() }}>View Perio Graph</button>
                    <button className="theme-btn bgDarkBlue  mt-3 mx-lg-2 mx-md-2 mx-sm-2" disabled={disabled} onClick={() => setChartSettingModal((pre) => ({ ...pre, open: true }))} ><SettingsIcon style={{ height: "20px" }} />Setting</button>
                    <button className="theme-btn bgDarkBlue2 mt-3 ms-3" onClick={handlePrint}> <PrintIcon style={{ height: "20px" }} />Print</button>
                </div>
            </div>
            <div className="row">
                <div className='perioTable mt-2 col-lg-10 mb-4 print-padding' ref={inputRefs}>
                    {perioChartForm?.map((chart) => (
                        <div key={chart.type}>
                            <h5 className='my-2'>{chart.type} Buccal</h5>
                            <table border="1">
                                <thead>
                                    <tr className='perioTable_Header'>
                                        <th className='ps-2'>Tooth Number</th>
                                        {chart.buccal.map((tooth) => (
                                            <th className='ps-4' key={`buccal-${tooth.tooth_no}`}>{tooth.tooth_no}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{renderRows(chart.type, 'buccal')}</tbody>
                            </table>
                            <h5 className='my-2'>{chart.type} Lingual</h5>
                            <table border="1">
                                <thead>
                                    <tr className='perioTable_Header'>
                                        <th className='ps-2'>Tooth Number</th>
                                        {chart.lingual.map((tooth) => (
                                            <th className='ps-4' key={`lingual-${tooth.tooth_no}`}>{tooth.tooth_no}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{renderRows(chart.type, 'lingual')}</tbody>
                            </table>
                        </div>
                    ))}

                </div>
                <div className="col-lg-2 ">
                    <div className='charitng_Position'>
                        {
                            assignedChart?.length > 0 &&
                            <>
                                <div className='charting_List mt-2'>
                                    {originalFields?.map((item, index) => {
                                        if (item === 'IsShowTooth') return null;
                                        const chartField = item?.replace(/ /g, '_')?.toLowerCase()
                                        return (
                                            <button
                                                type="button"
                                                // className={`${selectInput === index ? 'cus_Btn_Select' : 'cus_Btn'}  ${['Radiographic Bone Loss', 'Bleeding On Probing', 'Horizontal Probing', 'Gingival Margin'].includes(item) ? 'w-100' : ''}`}
                                                className={`${selectInput === index ? 'cus_Btn_Select' : 'cus_Btn'} `}
                                                onClick={() => handleButtonClick(chartField, index)}
                                                key={index}
                                                disabled={disabled}
                                            >
                                                {item === 'Gingival Margin' ? 'Recession' : item}
                                            </button>
                                        );
                                    })}
                                </div>
                                <h6 className='mt-2 text-center'>Auto Advance</h6>
                                <div className='charting_List'>
                                    <button type="button" disabled={disabled} className={`${chartPosition === 'rightToLeft' ? 'chart_Position_Btn_Select' : 'chart_Position_Btn'} mt-2 w-100`} onClick={() => setChartPosition('rightToLeft')}>Chart right to left </button>
                                    <button type="button" disabled={disabled} className={`${chartPosition === 'leftToRight' ? 'chart_Position_Btn_Select' : 'chart_Position_Btn'} mt-2 w-100`} onClick={() => setChartPosition('leftToRight')}>Chart left to right </button>
                                </div>

                                <h6 className='mt-2 text-center'>Set Defaults</h6>
                                <div className='charting_List'>
                                    <div className='d-flex'>
                                        <button disabled={disabled} type="button" onClick={() => handleFocusChange('left')} className={`cus_Btn d-flex justify-content-center`}><span className='bg-primary rounded-circle' style={{ marginTop: '1px' }}><ChevronLeftIcon className='text-light font-20' /></span></button>
                                        <button disabled={disabled} type="button" onClick={() => handleFocusChange('right')} className={`cus_Btn d-flex justify-content-center`}><span className='bg-primary rounded-circle' style={{ marginTop: '1px' }}><ChevronRightIcon className='text-light font-20' /></span></button>
                                    </div>
                                    <div className='mt-2 text-center'>
                                        {
                                            btnNo?.map((item, index) =>
                                                <button type="button" disabled={disabled} className={`chartNo ms-2 mb-2`} key={index} onClick={() => Object.keys(focusedInput)?.length && handleNumberType(item)}>{item}</button>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='d-flex justify-content-around align-items-center mt-2 ali charting_List'>
                                    <h6 className='mt-2 '>Auto Save</h6>
                                    <Switch checked={autoSave} value={autoSave} disabled={disabled} onChange={(e) => { autoSaveToggle(e.target.checked) }} />
                                </div>
                                <div className='mt-2 charting_List'>
                                    <h6 className='ms-4'>Numbers in red if &gt;=</h6>
                                    <table className="table  d-flex justify-content-center mt-3">
                                        <tbody>
                                            {Object.entries(values).map(([label, value], index) => (
                                                <tr key={index}>
                                                    <td className='pt-3 font-13 fw-5'>
                                                        {label === 'mobility' ? 'Mobility' :
                                                            label === 'horizontal_probing' ? 'Horizontal Probing' : label === 'gingival_margin' ? 'Recession' : 'Probing Depth'}
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <IconButton onClick={() => !disabled && handleDecrement(label)} size="small">
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <Typography className='font-16 mt-1'>{value}</Typography>
                                                            <IconButton onClick={() => !disabled && handleIncrement(label)} size="small">
                                                                <AddIcon />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            {/* Form Modal add Charts*/}
            <Modal className="p-0"
                size="xl"
                show={chartSettingModal?.open} onHide={() => {
                    setChartSettingModal({ data: {}, open: false })
                    clearFunction()

                }}
                headerContent={<h5 className="py-2 mb-0 fw-6">Chart Setting</h5>}
                bodyContent={
                    <>
                        <div className="row  m-2 p-2" style={{ background: "#fff" }}>
                            <div className='row my-3' style={{ height: "62vh" }}>
                                <div className='col-6 h-100' >
                                    <span className='font-18 text blue1 fw-6'>Chart Modules</span>
                                    <div className='border rounded bgGrey2 p-3 dragging mt-3'
                                        onDragOver={handleDragOver}
                                        onDrop={(event) => handleDrop(event, 'allPermissions')}
                                        style={{ overflow: "auto", height: "62vh" }}
                                    >
                                        {allChart?.map((item, index) => (
                                            item !== 'Cal' && (
                                                <div key={index}
                                                    draggable
                                                    onDragStart={(event) => handleDragStart(event, item, 'allPermissions')}
                                                    onClick={(event) => handleItemClick(event, item, 'allPermissions')}
                                                    className={`border rounded bg-white m-2 py-1 px-3 text-align-center ${selectedAllPermissions.includes(item) ? 'active' : ''
                                                        }`}
                                                >
                                                    {/* <span className='font-12'>{item==='Gingival Margin'?'Gingival Margin':item}</span> */}
                                                    <span className='font-12'>
                                                        {item === 'Gingival Margin' ? 'Recession' : item !== 'Cal' ? item : null}
                                                    </span>


                                                </div>
                                            )))}
                                    </div>
                                </div>

                                <div className='col-6 h-100' >
                                    <span className='font-18 blue1 fw-6'>Assigned Modules</span>
                                    <div className='border rounded bgGrey2 p-3 dragging mt-3'
                                        onDragOver={handleDragOver}
                                        onDrop={(event) => handleDrop(event, 'assignedPermissions')}
                                        style={{ overflow: "auto", height: "62vh" }}
                                    >
                                        {assignedPermissions.map((item, index) => (
                                            item !== 'Cal' && (
                                                <div key={index}
                                                    draggable
                                                    onDragStart={(event) => handleDragStart(event, item, 'assignedPermissions')}
                                                    onClick={(event) => handleItemClick(event, item, 'assignedPermissions')}
                                                    className={`border rounded bg-white m-2 py-1 px-3 text-align-center ${selectedAssignedPermissions.includes(item) ? 'active' : ''
                                                        }`}
                                                >
                                                    <span className='font-12'>
                                                        {item === 'Gingival Margin' ? 'Recession' : item !== 'Cal' ? item : null}
                                                    </span>

                                                </div>
                                            )))}
                                    </div>
                                </div>


                            </div>

                            <div className="d-flex justify-content-end mb-3 mt-5">
                                <button type="submit" className="theme-btn bgLightGray  px-5" onClick={() => clearFunction()}>Discard</button>
                                <button type="button" className="theme-btn bgDarkBlue  ms-2 px-5" onClick={() => handleSubmit()}>Save</button>
                            </div>
                        </div>
                    </>
                }
            />
            {/* Form Modal add Procedure */}
            <Modal className="p-0"
                size="md" show={chartAddModal?.open} onHide={() => { setChartAddModal({ open: false, data: {} }) }}
                bodyContent={
                    <div className="d-flex justify-content-center my-5">
                        <button type="button" className={`theme-btn ${!selectedTime ? 'bgLightGray' : 'bgDarkBlue'} ms-2 px-5`} disabled={!selectedTime} onClick={() => previousCopyChart()}>Copy Pervious Chart</button>
                        <button type="button" className="theme-btn bgDarkBlue  ms-2 px-5" onClick={() => { newChartAdd() }}>Create New Chart</button>
                    </div>
                } />
        </div>
    )
}

export default PerioChart
