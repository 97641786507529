import Modal from 'react-bootstrap/Modal';
import React from 'react';

const Index = ({ show, onHide, headerContent, bodyContent, size, CloseFucntion, HeaderClass, className, headerBorderBottom, backdrop, hideModal, closeButton = false,
}) => {
    return (
        <Modal
            size={size}
            show={show}
            onHide={onHide}
            centered
            backdrop={backdrop}
            className={`d-${hideModal ? 'none' : 'block'}`}
        >
            {headerContent && (
                <Modal.Header
                    className={HeaderClass ? HeaderClass : 'py-3'}
                    style={{
                        borderBottom: headerBorderBottom
                            ? 'none'
                            : '1px solid #D5DBE4',
                    }}
                    onClick={CloseFucntion}
                >
                    {headerContent}
                    {closeButton && (
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onHide}
                        ></button>
                    )}
                </Modal.Header>
            )}
            <Modal.Body className={className}>{bodyContent}</Modal.Body>
        </Modal>
    );
};

export default Index;
