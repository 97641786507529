/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
// Header.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from './Logo';
import './header.css';
import { useNavigate } from 'react-router-dom';
import AddIcon from "../../Assets/Clinic_head_icons/addIcon.png"
import teamIcon from "../../Assets/Clinic_head_icons/teamIcon.png"
import queryIcon from "../../Assets/Clinic_head_icons/ep_list.png"
import material_1 from "../../Assets/Clinic_head_icons/material-symbols_inbox-1.png"
import userIcon from "../../Assets/Clinic_head_icons/mdi_user.png"
import timeIcon from "../../Assets/Clinic_head_icons/mingcute_time-fill.png"
import setupIcon from "../../Assets/Clinic_head_icons/ph_gear-six-fill.png"
import { IconButton, Icon, MenuItem, Menu, } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddPatient from '../../Pages/PatientManagement/AddPatient';
import Modal from "../../Components/PopUpModal";
import AddFamilyMember from '../../Pages/PatientManagement/AddFamilyMember';
import SelectPatient from '../../Pages/PatientManagement/SelectPatient';
import { IoLogOut } from "react-icons/io5";
import useReduxStore from '../../hooks/useReduxStore';
import { add, update } from "../../Slices/AuthSlice";

import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Spinner } from '../common-components/spinner';
import { Permission } from '../../Utils';
import { patientDetails } from '../../Slices/PatientSlice';
import { AiOutlinePlusSquare } from "react-icons/ai";
import Badge from '@mui/material/Badge';
import { IoMdNotificationsOutline } from "react-icons/io";
import { NotificationsState } from '../../App';
import { NOTIFICATION_LIST } from '../../repository/NotificationRepo';
import useBroserNotification from '../../hooks/useBroserNotification';
import { userProfileURL } from '../../repository/common';
import Avatar from '@mui/material/Avatar';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Permission_Message } from '../common-components/permissionMessage';
const Header = ({ data, methods, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const [submenuAnchorElCharting, setSubmenuAnchorElCharting] = useState(null);
    const { selector: PatientData } = useReduxStore("PatientData")
    const navigate = useNavigate()
    const [notificationList, setNotificationList] = useState([])
    const notificationRef = useRef(null)
    const [openNotification, setOpenNotification] = useState(false);
    const { notificationcount, socket, setNotificationcount } = useContext(NotificationsState)
    const { showNotification } = useBroserNotification()



    const open = Boolean(anchorEl);
    const { addPatientModal, familyMemberModal, selectPatientModal, spinner, ClinicLocationListListData } = data
    const { setAddPatientModal, setFamilyMemberModal, setSelectPatientModal, clearForm, logout
    } = methods

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { Dispatch, selector: user } = useReduxStore("auth")

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSubmenuAnchorEl(null);
        setSubmenuAnchorElCharting(null);
    };

    const handleChangeLocation = (item) => {
        Dispatch(update(item))
        setTimeout(() => {
            window.location.reload()
        }, 800)
    }


    const Add_Family_Permission = Permission({ permission: 'Patient Management-Able to add family member' })
    const Add_Patient_Permission = Permission({ permission: 'Patient Management-Able to add patient' })
    const View_Permission = Permission({ permission: 'Notifications-Able to view notifications' })
    // console.log(user, 'user?.permissions')


    const getNotificationList = async (newPage) => {
        let payload = {
            count: 10,
            page: newPage,
            clinicId: user?.clinic_id,
            userId: user?.user_id,
            filter: "UNREAD"
        }
        try {
            let response = await NOTIFICATION_LIST(payload)
            setNotificationList((prevList) =>
                newPage === 1 ? response.data : [...prevList, ...response.data]
            );
            let totalPage = Math.ceil(response.totalCount / 10)
            setTotalPages(totalPage)
            setNotificationcount(response?.unreadCount)
        } catch (error) {
            setNotificationList([])
            setNotificationcount(0)
        }
    }

    const handleClickNotification = () => {
        setOpenNotification((pre) => !pre);
    };
    const handleClickViewAll = () => {
        setOpenNotification(false)
        navigate("notification")
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setOpenNotification(false);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (socket && socket?.readyState === WebSocket?.OPEN) {
            const handleMessage = (event) => {
                const socketData = JSON.parse(event.data);
                if (socketData?.data?.eventType === 'newNotification') {
                    getNotificationList(1);
                    let notificationData = {
                        message: socketData?.data?.message,
                        type: socketData?.data?.type,
                        title: socketData?.data?.title
                    };
                    const setNotificationData = (routes) => {
                        notificationData.routes = routes;
                    };
                    switch (socketData?.data?.type) {
                        case "ASSIGNPROCEDURECODE": case "ASSIGNLOCATION": case "ASSIGNOPERATORY":
                            setNotificationData("/team-management");
                            break;
                        case "BOOKAPPOINTMENT": case "CANCELAPPOINTMENT": case "RESCHEDULEAPPOINTMENT":
                            setNotificationData(`/appointment_scheduler`);
                            break;
                        case "CREATENEWGROUP": case "GROUPADDED":
                            setNotificationData("/inbox");
                            break;
                        default:
                            break;
                    }
                    showNotification(notificationData);
                }
                if (socketData?.eventType === 'receivedMessage') {
                    let notificationData = {
                        message: socketData?.data?.message,
                        type: socketData?.data?.type,
                        title: socketData?.data?.title,
                        routes: "/inbox"
                    };
                    showNotification(notificationData);
                }
            };
            socket.addEventListener('message', handleMessage);

            return () => {
                socket.removeEventListener('message', handleMessage);
            };
        }

    }, [socket]);

    const handleScroll = (e) => {
        const target = e.target;
        if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
            if (selectedPage !== totalPages) {
                setSelectedPage((prevPage) => {
                    const newPage = prevPage + 1;
                    getNotificationList(newPage);
                    return newPage;
                });
            }
        }
    };
    useEffect(() => {
        if (notificationList.length === 0 && selectedPage === 1) {
            getNotificationList(1)
        }
    }, [])
    useEffect(() => {
        if (openNotification) {
            getNotificationList(1)
        }
    }, [openNotification])

    const tabStyles = {
        minHeight: "30px",
        backgroundColor: '#0F6CBD',
        color: 'white',
        fontSize: '11px',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '20%',
            height: '60%',
            width: '1px',
            backgroundColor: '#619acd',
        },
        borderBottom: '1px solid #619acd',
        '&.Mui-selected': {
            color: 'white',
        },
    };
    const tabIconStyles = {
        width: 20,
        height: 20,
        marginRight: '3px',
    };


    const tabsContainerStyles = {
        backgroundColor: '#0F6CBD',
        padding: "0",
        margin: "0",
        height: "30px",
        position: 'relative', // Ensure hover effect works properly
        '& .MuiTabs-scrollButtons': {
            opacity: 0, // Hide scroll buttons by default
            transition: 'opacity 0.3s ease', // Smooth transition for showing buttons
        },
        '&:hover .MuiTabs-scrollButtons': {
            opacity: 1, // Show scroll buttons on hover on larger screens
        },
        // Always show scroll buttons on small screens
        '@media (max-width: 600px)': {
            '& .MuiTabs-scrollButtons': {
                opacity: 1, // Always show scroll buttons on small screens
            },
        },
    };


    return (
        <>
            {spinner && <Spinner />
            }
            <header id="header" className='logo-header d-flex align-items-center'>
                <Logo />
            </header>
            <header id="header" className='header  d-flex align-items-center justify-content-between'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    sx={tabsContainerStyles}// Set background color for Tabs container
                >
                    <Tab
                        label="Select patient"
                        icon={<img alt='User' src={userIcon} style={tabIconStyles} />}
                        onClick={() => { handleMenuClose(); setSelectPatientModal((prev) => ({ ...prev, open: true })) }}
                        sx={tabStyles} // Apply common styles
                    />

                    <Tab
                        label="Add Patient"
                        icon={!Add_Patient_Permission ? <AiOutlinePlusSquare className='fs-5 text-white ' /> :
                            <img alt='' src={AddIcon} style={tabIconStyles} />}
                        onClick={() => {
                            if (Add_Patient_Permission) {
                                clearForm();
                                handleMenuClose();
                                setAddPatientModal((prev) => ({ ...prev, open: true, type: "ADD" }));
                            }
                        }}
                        sx={tabStyles}
                        disabled={!Add_Patient_Permission} // Disable the tab if permission is not granted
                    />
                    <Tab
                        label="Add Family Member"
                        icon={!PatientData?.firstName || !Add_Family_Permission ? <AiOutlinePlusSquare className='fs-5 text-white ' /> :
                            <img src={AddIcon} alt='img' style={tabIconStyles} />}
                        onClick={() => {
                            if (PatientData?.firstName) {
                                if (Add_Family_Permission) {
                                    clearForm();
                                    handleMenuClose();
                                    setFamilyMemberModal((prev) => ({ ...prev, open: true, type: "ADD" }));
                                }
                            }
                        }}
                        sx={tabStyles}
                        disabled={!PatientData?.firstName || !Add_Family_Permission} // Disable if no patient name or permission is not granted
                    />
                    <Tab
                        label="Inbox"
                        icon={<img src={material_1} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/inbox')}
                        sx={tabStyles}
                    />
                    <Tab
                        // label="Item Two"
                        icon={
                            <IconButton
                                className='p-0'
                                size="small"
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                sx={{ fontSize: "11px", color: "#fff" }}
                            >
                                <img src={setupIcon} alt='Setup Icon' style={tabIconStyles} />
                                Setup <Icon as={KeyboardArrowDownIcon} sx={{ width: 25, height: 25 }} />
                            </IconButton>
                        }
                        sx={tabStyles}
                    />

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >

                        <MenuItem onClick={() => { handleMenuClose(); navigate('/adjustment_type') }}>
                            <span className='font-12 ms-1 fw-5'>Adjustment Type</span>
                        </MenuItem>
                        <MenuItem
                            className='font-12 d-flex justify-content-between'
                            aria-controls={`services-submenu`}
                            aria-haspopup="true"
                            onClick={(event) => setSubmenuAnchorEl(event.currentTarget)}
                        >
                            <span className='font-12 ms-1 fw-5'>Appointment</span>
                            {submenuAnchorEl ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/billing_type') }}>
                            <span className='font-12 ms-1 fw-5'>Billing Type</span>
                        </MenuItem>
                        <MenuItem
                            className='font-12 d-flex justify-content-between'
                            aria-controls={`services-submenu`}
                            aria-haspopup="true"
                            onClick={(event) => setSubmenuAnchorElCharting(event.currentTarget)} >
                            <span className='font-12 ms-1 fw-5'>Charting</span> {submenuAnchorElCharting ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/claim-processing') }}>
                            <span className='font-12 ms-1 fw-5'>Claim Processing</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/clinic-shift') }}>
                            <span className='font-12 ms-1 fw-5'>Clinic Shift</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/diagnosis') }}>
                            <span className='font-12 ms-1 fw-5'>Diagnosis</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/discount_plan') }}>
                            <span className='font-12 ms-1 fw-5'>Discount Plan</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/employers') }}>
                            <span className='font-12 ms-1 fw-5'>Employers</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/fee_schedule') }}>
                            <span className='font-12 ms-1 fw-5'>Fees Schedule</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/insurance_plan') }}>
                            <span className='font-12 ms-1 fw-5'>Insurance Plan</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/medical_history') }}>
                            <span className='font-12 ms-1 fw-5'>Medical History</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/medications') }}>
                            <span className='font-12 ms-1 fw-5'>Medications</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/macros') }}>
                            <span className='font-12 ms-1 fw-5'>Macros</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/notes_types') }}>
                            <span className='font-12 ms-1 fw-5'>Note Types</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/payment-gate-way') }}>
                            <span className='font-12 ms-1 fw-5'>Payment Gateway</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/pharmacies') }}>
                            <span className='font-12 ms-1 fw-5'>Pharmacies</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/polices') }}>
                            <span className='font-12 ms-1 fw-5'>Policies</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/procedure_list') }}>
                            <span className='font-12 ms-1 fw-5'>Procedure Codes</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/referral_slip') }}>
                            <span className='font-12 ms-1 fw-5'>Referral Slip</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/roles-&-permission') }}>
                            <span className='font-12 ms-1 fw-5'>Roles & Permission</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/scheduler') }}>
                            <span className='font-12 ms-1 fw-5'>Scheduler</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/security') }}>
                            <span className='font-12 ms-1 fw-5'>Security</span>
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); navigate('/template_library') }}>
                            <span className='font-12 ms-1 fw-5'>Template Library</span>
                        </MenuItem>

                        <Menu
                            id={`services-submenu`}
                            anchorEl={submenuAnchorEl}
                            open={Boolean(submenuAnchorEl)}
                            onClose={() => setSubmenuAnchorEl(null)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <MenuItem onClick={() => { handleMenuClose(); navigate('/appointment_types') }}>
                                <span className='font-12 ms-1 fw-5'>Appointment Types</span>
                            </MenuItem>
                            <MenuItem onClick={() => { handleMenuClose(); navigate('/reminders') }}>
                                <span className='font-12 ms-1 fw-5'>Reminders</span>
                            </MenuItem>
                            <MenuItem onClick={() => { handleMenuClose(); navigate('/recall_types') }}>
                                <span className='font-12 ms-1 fw-5'>Recall Types</span>
                            </MenuItem>
                        </Menu>

                        <Menu
                            id={`services-submenu`}
                            anchorEl={submenuAnchorElCharting}
                            open={Boolean(submenuAnchorElCharting)}
                            onClose={() => setSubmenuAnchorElCharting(null)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                            {/* <MenuItem onClick={() => { handleMenuClose(); navigate('/colors') }}>
                                <span className='font-12 ms-1 fw-5'>Colors</span>
                            </MenuItem> */}
                            <MenuItem onClick={() => { handleMenuClose(); navigate('/conditions') }}>
                                <span className='font-12 ms-1 fw-5'>Conditions</span>
                            </MenuItem>
                            <MenuItem onClick={() => { handleMenuClose(); navigate('/materials') }}>
                                <span className='font-12 ms-1 fw-5'>Materials</span>
                            </MenuItem>

                        </Menu>
                    </Menu>
                    <Tab
                        label="Team Management"
                        icon={<img src={teamIcon} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/team-management')}
                        sx={tabStyles}
                    />
                    <Tab
                        label="Clinic Location"
                        icon={<img src={material_1} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/clinic-location')}
                        sx={tabStyles}
                    />
                    <Tab
                        label="Punch In & Out"
                        icon={<img src={timeIcon} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/punch_in_out')}
                        sx={tabStyles}
                    />
                    <Tab
                        label="Queries"
                        icon={<img src={queryIcon} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/queries')}
                        sx={tabStyles}
                    />
                    <Tab
                        label="Audit Trails"
                        icon={<img src={queryIcon} alt='img' style={tabIconStyles} />}
                        onClick={() => navigate('/audit-trails')}
                        sx={tabStyles}
                    />
                </Tabs>
                <div className='d-flex justify-content-center '>
                    {/* <p className='white me-3  d-flex'>
                        <div className='d-flex justify-content-center me-1 cursor mt-1' style={{ background: punch ? 'red' : 'green', borderRadius: '50%', border: '1px solid white', width: '20px', height: '20px' }}>
                            <PowerSettingsNewIcon className='font-18' onClick={() => { punchInOut(punch ? 'PUNCHIN' : 'PUNCHOUT') }} />
                        </div>
                        <span className='font-13 mt-1 cursor' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> {punch ? 'Punch In' : 'Punch Out'}</span>
                        <ArrowDropDownIcon className='cursor' aria-labelledby="dropdownMenuLink" />
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item cursor" onClick={() => navigate('/punch_in_out')}>Attendance</a>
                        </ul>
                    </p> */}

                    <Badge
                        ref={notificationRef}
                        onClick={handleClickNotification}
                        className="cursor me-3 position-relative"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        color="error"
                        badgeContent={notificationcount}>
                        <IoMdNotificationsOutline size={30} style={{ color: "#fff" }} />
                        {
                            openNotification &&
                            <div className='position-absolute shadow NOTIFICATION_BOX  ' onClick={(e) => e.stopPropagation()} >
                                {/* <ul className=' pt-2 SCROLL_NOTIFICATION_LIST' onScroll={handleScroll} style={{ height: "30vh" }}> */}
                                {View_Permission ? <ul className=' pt-2 SCROLL_NOTIFICATION_LIST' onScroll={handleScroll}>
                                    {
                                        notificationList?.map((cur, index) => {
                                            return <li style={{
                                                padding: "6px 5px",
                                                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff"  // Alternating background colors
                                            }} key={cur?.notificationId}  >{cur?.message}</li>
                                        })
                                    }
                                    {notificationList?.length === 0 && <li className='text-center position-absolute' style={{ top: "40%" }}>No Notification</li>}
                                </ul> : <div className=' pt-2 SCROLL_NOTIFICATION_LIST'>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <p className='black'>You don't have permission to access</p>
                                    </div>
                                </div>}
                                <div className='MORE' >
                                    <p onClick={handleClickViewAll}>View All</p>
                                </div>
                            </div>
                        }

                    </Badge>

                    <div className='white me-3  d-flex'>
                        {/* <div className='d-flex justify-content-center me-1 cursor mt-1' data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#ccc', borderRadius: '50%', border: '1px solid white', width: '20px', height: '20px' }}> */}
                        {/* <img src={userLocation} alt='location' /> */}
                        <Avatar alt="Remy Sharp" src={userProfileURL + user?.defaultLocationImage} sx={{ height: '30px', width: '30px', marginRight: '5px' }} />
                        {/* </div> */}
                        <span className='font-11 mt-2 cursor' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ textWrap: "nowrap" }}>
                            {user?.locationName}
                        </span>
                        <ArrowDropDownIcon className='cursor' aria-labelledby="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            {ClinicLocationListListData?.length > 0 ? (
                                ClinicLocationListListData?.map((item) => (
                                    <li key={item?.id} className={user?.location_id == item?.id ? "bgDarkBlue2 " : ""} onClick={() => { handleChangeLocation({ location_id: item?.id, locationName: item?.name, defaultLocationImage: item?.image }) }} >
                                        <a className={` ${user?.location_id == item?.id ? "grey4" : ""} dropdown-item cursor`}  >
                                            {item?.name} {[item?.addressLine1, item?.addressLine2, item?.cityName].filter(Boolean).join(', ')}
                                        </a>
                                    </li>
                                ))
                            ) : (
                                <li><a className="dropdown-item">No locations found</a></li>
                            )}
                        </ul>
                    </div>
                    <p className='m-0 d-flex  cursor white font-11 mt-1' onClick={logout} > <IoLogOut className="font-22" /><span className='mt-1'>Logout</span></p>
                </div>
            </header>

            {/* select patient modal */}
            <Modal className="p-0"
                size="xl" show={selectPatientModal?.open} onHide={() => {
                    setSelectPatientModal({ data: null, open: false })
                    // clearForm()
                    // setStateError({});
                }}
                headerContent={<h5 className="py-2 mb-0 ">Select Patient</h5>}
                bodyContent={
                    <SelectPatient data={data} methods={methods} />
                }
            />
            {/* add patient modal */}
            <Modal className="p-0"
                size="xl" show={addPatientModal?.open} onHide={() => {
                    setAddPatientModal({ data: null, open: false })
                    // clearForm()
                    // setStateError({});
                }}
                headerContent={<h5 className="py-2 mb-0 font-16 fw-6">Add Patient</h5>}
                bodyContent={
                    <AddPatient data={data} methods={methods} />
                }
            />

            {/* add family member modal */}
            <Modal className="p-0"
                size="xl" show={familyMemberModal?.open} onHide={() => {
                    setFamilyMemberModal({ data: null, open: false })
                    // clearForm()
                    // setStateError({});
                }}
                headerContent={<h5 className="py-2 mb-0 font-16 fw-6">Add Family Member</h5>}
                bodyContent={
                    <AddFamilyMember data={data} methods={methods} />
                }
            />
        </>
    );
}

export default Header;
